import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import AnimationRevealPage from '../components/AnimationRevealPage';
import TextDiplay1 from '../components/TextDisplay1';
import TextDiplay2 from '../components/TextDisplay2';
import Partenaires from '../components/Partenaires';

export default function Etablissement() {
  return (
    <AnimationRevealPage>
      <Layout>
        <Seo
          title="Collègé Jules Verne - L'établissement"
          description="N'hésitez pas à nous contacter pour toutes questions ou demande"
        />

        <TextDiplay1 />
        <TextDiplay2 />
        <Partenaires />
      </Layout>
    </AnimationRevealPage>
  );
}
